
import Vue from 'vue'
import { mapMutations, mapState } from 'vuex'
import * as login from '../services/login.service'
import { SET_STEP, SET_USER } from '../stores/login.mutation-types'
import { LoginState } from '../login.types'
export default Vue.extend({
  data: () => ({
    error: null
  }),
  components: {
    LoginError: () => import('./LoginError.vue')
  },
  computed: {
    ...mapState({
      form: (state: any) => (state.login as LoginState).form,
      step: (state: any) => (state.login as LoginState).step,
      user: (state: any) => (state.login as LoginState).user
    }),
    valid(): boolean {
      return !!(this.form.email && this.form.password)
    }
  },
  methods: {
    ...mapMutations({
      setStep: `login/${SET_STEP}`,
      setUser: `login/${SET_USER}`
    }),
    async logIn() {
      this.$mxp.track('user_login')
      this.$wait.start('loading')
      try {
        const user = await login.getBranch(this.form)
        this.setUser(user)

        const userIsSetup = await login.checkFirstLogin(user.id)

        if (!userIsSetup) {
          return this.setStep(2)
        }

        this.$emit('attempt-login')
      } catch (err) {
        this.error = this.$handleError(err).message()
      } finally {
        this.$wait.end('loading')
      }
    }
  }
})
